import React from "react";

// Components
import Video from "../Video/Index";
import TextMediaButton from "../TextMedia/Button";

interface Props {
  section?: "default" | "muted" | "primary" | "secondary";
  grid?: "small" | "medium" | "large";
  title?: string | undefined;
  heading?: string | null;
  divider?: boolean | undefined;
  alignment?: "left" | "center";
  summary?: string | null;
  footnote?: string | null;
  columns?: "3" | "4";
  backgroundMediaUrl?: string | undefined;
  backgroundMediaTitle?: string | undefined;
  backgroundMediaWidth?: string | undefined;
  content?: string | null;
  buttonUrl?: string | undefined;
  buttonValue?: string | null;
  buttonTarget?: "_self" | "_blank";
  buttonRel?: string | undefined;
  removePadding?: boolean | null;
  videoMediaType?: "youtube" | "vimeo";
  videoMediaId?: string | undefined;
  data?: any | undefined;
}

const defaultProps: Props = {
  section: "default",
  grid: "large",
  divider: false,
  alignment: "center",
  columns: "3",
  buttonValue: "Learn More",
  removePadding: false,
};

const FeatureColumn: React.FC<Props> = (props) => {
  const {
    section,
    grid,
    divider,
    heading,
    summary,
    alignment,
    footnote,
    columns,
    removePadding,
    data,
    buttonUrl,
    buttonValue,
    buttonRel,
    buttonTarget,
  } = props;

  const hasContent = !heading && !summary ? false : true;

  return (
    <section
      className={`c-feature-columns uk-section uk-text-center c-noprint ${
        removePadding ? "uk-section-small" : ""
      } uk-section-${section}`}
    >
      <div className={`uk-container uk-text-${alignment}`}>
        {hasContent && (
          <div className="uk-margin-large-bottom">
            {heading && (
              <h2
                className="uk-h5 uk-h4@m uk-h3@l uk-h2@xl"
                dangerouslySetInnerHTML={{ __html: heading }}
              />
            )}
            {divider && <hr className="uk-divider-small" />}
            {summary && (
              <div
                className="uk-text-meta uk-p@m uk-margin-top uk-margin-bottom uk-width-1-1 uk-width-1-2@m uk-margin-auto"
                dangerouslySetInnerHTML={{ __html: summary }}
              />
            )}
          </div>
        )}
        <div className="c-feature-columns__items uk-margin-medium">
          <div
            data-uk-grid=""
            className={`uk-child-width-1-1 uk-child-width-1-${columns}@m uk-text-center uk-grid-${grid}`}
            data-uk-scrollspy="target: > *; cls: uk-animation-slide-bottom-small; delay: 300"
          >
            {data &&
              data.map((item: any, index: number) => {
                return (
                  <FeatureColumnItem
                    key={`content_item_${index}`}
                    backgroundMediaUrl={item.backgroundMediaUrl}
                    backgroundMediaTitle={item.backgroundMediaTitle}
                    backgroundMediaWidth={item.backgroundMediaWidth}
                    title={item.title}
                    content={item.content}
                    buttonUrl={item.buttonUrl}
                    buttonValue={item.buttonValue}
                    buttonTarget={item.buttonTarget}
                    buttonRel={item.buttonRel}
                    videoMediaType={item.videoMediaType}
                    videoMediaId={item.videoMediaId}
                  />
                );
              })}
          </div>
        </div>
        {footnote && (
          <div
            className="c-feature-columns__footnote uk-margin-large-top uk-width-1-1 uk-width-2-3@m uk-margin-auto uk-p@s"
            dangerouslySetInnerHTML={{ __html: footnote }}
          />
        )}
        {buttonUrl && buttonValue && (
          <TextMediaButton
            buttonUrl={buttonUrl}
            buttonValue={buttonValue}
            buttonRel={buttonRel}
            buttonTarget={buttonTarget}
          />
        )}
      </div>
    </section>
  );
};

const FeatureColumnItem: React.FC<Props> = (props) => {
  const {
    backgroundMediaUrl,
    backgroundMediaTitle,
    backgroundMediaWidth,
    title,
    content,
    buttonUrl,
    buttonValue,
    buttonTarget,
    buttonRel,
    videoMediaId,
    videoMediaType,
  } = props;

  return (
    <div>
      <div className="c-feature-columns__item uk-flex uk-flex-column uk-height-1-1">
        <Video
          backgroundMediaUrl={backgroundMediaUrl}
          backgroundMediaTitle={backgroundMediaTitle}
          backgroundMediaWidth={backgroundMediaWidth}
          buttonUrl={buttonUrl}
          buttonValue={buttonValue}
          buttonTarget={buttonTarget}
          buttonRel={buttonRel}
          videoMediaId={videoMediaId}
          videoMediaType={videoMediaType}
          videoPlayIconGreen={true}
        />
        <div className="c-feature-columns__content uk-margin-auto uk-width-1-1 uk-width-5-6@l uk-flex uk-flex-column">
          {title && (
            <h2 className="uk-h5 uk-h5@m uk-h4@l uk-h4@xl uk-margin-small">
              {title}
            </h2>
          )}
          {content && <p className="uk-margin-small">{content}</p>}
          {buttonUrl && buttonValue && (
            <div className="c-feature-columns__button uk-flex-wrap-bottom">
              <a
                href={buttonUrl}
                target={buttonTarget}
                rel={buttonRel}
                className="uk-button uk-button-link"
              >
                {buttonValue}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeatureColumn;

FeatureColumn.defaultProps = defaultProps;
