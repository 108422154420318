import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import BannerBasic from "../components/Content/Banner/Basic";
import FeatureColumn from "../components/Content/FeatureColumn/Index";
import LeadIn from "../components/Content/LeadIn/Index";

// data
import data from "../pages/static-pages/content/resources.yaml";

// assets
import iconSearch from "../assets/icons/icon-search.svg";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Resources"
        description="Get answers quick. Check out our frequently asked questions"
      />
      <BannerBasic title="Resources" />
      <FeatureColumn grid="medium" data={data.columns} />
      <LeadIn
        section="primary"
        brandMediaUrl={iconSearch}
        brandMediaTitle="Download"
        brandMediaWidth="69px"
        summary="Need help finding the right system for the job?"
        paddingSize="small"
        buttonValue="Launch Product Selector"
        buttonUrl="#js-product-selector-app"
        buttonModal={true}
      />
    </Layout>
  );
};

export default IndexPage;
