import React from "react";

// components
import AnchorWrapper from "../TextMedia/AnchorWrapper";

// assets
import playIcon from "../../../assets/icons/icon-play.svg";
import playIconGreen from "../../../assets/icons/icon-play-green.svg";

interface Props {
  margin?: boolean;
  buttonUrl?: string | undefined;
  buttonValue?: string | null;
  buttonTarget?: "_self" | "_blank";
  buttonRel?: string | undefined;
  backgroundMediaUrl: string | undefined;
  backgroundMediaTitle: string | undefined;
  backgroundMediaWidth?: string | undefined;
  videoMediaType?: "youtube" | "vimeo";
  videoMediaId?: string;
  videoPlayIconGreen?: boolean;
}

interface IMapping {
  [key: string]: any;
}

const videoUrl: IMapping = {
  youtube: "https://www.youtube.com/embed/",
  vimeo: "https://player.vimeo.com/video/",
  default: "https://www.youtube.com/embed/",
};

const Video: React.FC<Props> = (props) => {
  const {
    margin,
    backgroundMediaUrl,
    backgroundMediaTitle,
    backgroundMediaWidth,
    buttonUrl,
    buttonTarget,
    buttonRel,
    videoMediaId,
    videoMediaType,
    videoPlayIconGreen,
  } = props;

  return (
    <div
      className={`c-feature-columns__wrap ${margin ? "uk-margin-large" : ""}`}
    >
      <div className="uk-inline uk-overflow-hidden">
        <AnchorWrapper
          condition={buttonUrl ? true : false}
          wrapper={(children) => (
            <a
              href={buttonUrl}
              target={buttonTarget}
              rel={buttonRel}
              className="uk-animation-toggle"
            >
              <div className="uk-animation-kenburns">{children}</div>
            </a>
          )}
        >
          <img
            className="c-feature-columns__image"
            src={backgroundMediaUrl}
            alt={backgroundMediaTitle}
            width={backgroundMediaWidth}
          />
        </AnchorWrapper>
        {videoMediaId && (
          <div>
            <div className="uk-position-center uk-width-auto">
              <a
                className="c-play-icon"
                data-uk-toggle={`target: #js-modal-media-vimeo-${videoMediaId}`}
              >
                <span className="c-visually__hidden">Play video</span>
                <img
                  src={videoPlayIconGreen ? playIconGreen : playIcon}
                  alt=""
                />
              </a>
            </div>
            <div
              id={`js-modal-media-vimeo-${videoMediaId}`}
              className="uk-flex-top"
              data-uk-modal="true"
            >
              <div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                <button
                  className="uk-modal-close-outside"
                  type="button"
                  data-uk-close="true"
                >
                  <span className="c-visually__hidden">Close video</span>
                </button>
                <iframe
                  src={`${videoUrl[videoMediaType || "vimeo"]}${videoMediaId}`}
                  width="1280"
                  height="720"
                  frameBorder="0"
                  data-uk-video="true"
                  data-uk-responsive="true"
                  title="Video"
                ></iframe>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Video;
