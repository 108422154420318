import React from "react";

interface Props {
  buttonUrl: string;
  buttonValue: string;
  buttonRel?: string;
  buttonTarget?: string;
}

const TextMediaButton: React.FC<Props> = (props) => {
  const { buttonUrl, buttonValue, buttonRel, buttonTarget } = props;

  return (
    <div className="uk-width-1-1 uk-width-2-3@m uk-margin-auto uk-text-center">
      <div className="uk-margin-medium-top uk-margin-medium-bottom">
        <a
          href={buttonUrl}
          rel={buttonRel}
          target={buttonTarget}
          className="uk-button uk-button-primary"
        >
          {buttonValue}
        </a>
      </div>
    </div>
  );
};

export default TextMediaButton;
