import React from "react";

interface ConditonalWrapperProps {
  children: React.ReactElement;
  condition: boolean;
  wrapper: (children: React.ReactElement) => JSX.Element;
}

const AnchorWrapper: React.FC<ConditonalWrapperProps> = (props) => {
  const { condition, wrapper, children } = props;
  return <>{condition ? wrapper(children) : children}</>;
};

export default AnchorWrapper;
