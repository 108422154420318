import React from "react";

interface Props {
  section?: "default" | "muted" | "primary" | "secondary";
  subHeading?: string | null;
  brandHeading?: string | null;
  brandMediaUrl?: string | null;
  brandMediaTitle?: string | undefined;
  brandMediaWidth?: string | undefined;
  divider?: boolean | undefined;
  summary?: string | null;
  content?: string | null;
  buttonUrl?: string | "";
  buttonValue?: string | null;
  buttonModal?: boolean | null;
  buttonTarget?: "_self" | "_blank";
  buttonRel?: string | undefined;
  paddingSize?: "xsmall" | "small" | "medium" | "large" | "xlarge";
  footnote?: string | null;
  anchor?: string | undefined;
}

export interface AnchorProps extends React.HTMLProps<HTMLAnchorElement> {}

const defaultProps: Props = {
  section: "default",
  divider: false,
  buttonValue: "Learn More",
  paddingSize: "large",
};

const LeadIn: React.FC<Props> = (props) => {
  const {
    section,
    subHeading,
    brandHeading,
    brandMediaUrl,
    brandMediaTitle,
    brandMediaWidth,
    divider,
    summary,
    content,
    children,
    buttonUrl,
    buttonValue,
    buttonModal,
    buttonTarget,
    buttonRel,
    paddingSize,
    footnote,
    anchor,
  } = props;

  const anchorAttr = {
    href: buttonUrl,
    className: "uk-button uk-button-primary",
    target: buttonTarget,
    rel: buttonRel,
  };

  return (
    <section
      className={`c-leadin uk-section uk-section-${paddingSize} uk-section-${section} c-noprint`}
    >
      <div
        id={anchor}
        className="uk-container uk-text-center"
        data-uk-scrollspy="target: > *; cls: uk-animation-slide-bottom-small; delay: 100"
      >
        <div className="uk-width-1-1 uk-width-2-3@m uk-margin-auto">
          {subHeading && (
            <h2 className="uk-h4 uk-margin-small-bottom c-conex-green">
              {subHeading}
            </h2>
          )}
          {brandHeading && (
            <h3 className={`c-font-dax uk-margin-remove`}>{brandHeading}</h3>
          )}
          {brandMediaUrl && (
            <div
              className="c-leadin__brand uk-margin-auto"
              style={{ width: brandMediaWidth }}
            >
              <img
                width=""
                height=""
                alt={brandMediaTitle}
                src={brandMediaUrl}
                className="uk-width-1-1"
              />
            </div>
          )}
          {divider && (
            <hr className="uk-divider-small uk-margin-medium-bottom" />
          )}
          {summary && (
            <div
              className="uk-h5 uk-h4@m uk-h3@l uk-h2@xl uk-margin-top"
              dangerouslySetInnerHTML={{ __html: summary }}
            />
          )}
          {content && (
            <div
              className="uk-p@s"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </div>
        {/* Children */}
        {children}
        {footnote && (
          <div
            className="c-feature-columns__footnote uk-width-1-1 uk-width-2-3@m uk-margin-medium-top uk-margin-auto uk-p@s"
            dangerouslySetInnerHTML={{ __html: footnote }}
          />
        )}
        {buttonUrl && (
          <div className="uk-width-1-1 uk-width-2-3@m uk-margin-auto">
            <div className="uk-margin-medium-top uk-margin-medium-bottom">
              {buttonModal ? (
                <a {...anchorAttr} data-uk-toggle="">
                  {buttonValue}
                </a>
              ) : (
                <a {...anchorAttr}>{buttonValue}</a>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default LeadIn;

LeadIn.defaultProps = defaultProps;
